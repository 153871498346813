import { navigate, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { InlineWidget } from "react-calendly";
import Layout from '~/components/Layout';
import Image from '~/components/atoms/Image';
import CustomSEO from '~/components/seo/CustomSEO';
import { pricingResultsLabels } from '~/data/translations/pricingFormLabels';// import { StaticImage } from 'gatsby-plugin-image';

import './pricing-result.css';
import { StaticImage } from 'gatsby-plugin-image';

import { Chart as ChartJS, BarController, BarElement, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip } from 'chart.js';
import { Chart, Bar, Line } from 'react-chartjs-2';

export default function PricingResult(props) {

  const {
    location: { state },
    data: { wpPage },
  } = props;

  const section = wpPage?.pricingResult?.pricingResult
  let cityName = "london";//default value
  if (state && state.cityName) {
    cityName = state.cityName;//values can be: london , not_london
  }

  // console.log('cityName=', cityName);

  // todo test --------------------------------------------- start
  let occupancy = 0;//Avg. occupancy
  let annual_revenue = 0;//Annual revenue
  let occupancyRevenueByMonth = [];//array that maps Month=>Occupancy, Revenue
  let avg_daily_rate = 0;//Avg. daily rate
  let PerformanceChart;
  const [barThickness, setBarThickness] = useState(30); // Default for desktop
  useEffect(() => {
    const updateBarThickness = () => {
      if (window.innerWidth <= 768) {
        setBarThickness(12); // Smaller thickness for mobile
      } else {
        setBarThickness(30); // Default for larger screens
      }
    };

    // Set on initial load
    updateBarThickness();

    // Add event listener for resize
    window.addEventListener('resize', updateBarThickness);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updateBarThickness);
    };
  }, []);

  if (cityName == 'london') {
    if (state) {

      if (state.occupancy)
        occupancy = (state.occupancy) * 100;

      if (state.annual_revenue) {
        annual_revenue = state.annual_revenue.toFixed(2);
        // avg_daily_rate = (annual_revenue / 360).toFixed(2); // Returns a string with 2 decimal places
        avg_daily_rate = ((annual_revenue * state.occupancy) / 360).toFixed(2);//as per Antanas-Martin
      }

      if (state.occupancyRevenueByMonth) {
        occupancyRevenueByMonth = state.occupancyRevenueByMonth;
      }
      // console.log('state===>', state);
      // console.log('occupancy=', occupancy);
      // console.log('annual_revenue=', annual_revenue);
      // console.log('occupancyRevenueByMonth=', occupancyRevenueByMonth);
    }

    ChartJS.register(BarController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Legend, Tooltip);

    // Get the current year dynamically
    const currentYear = new Date().getFullYear();

    // Map of month abbreviations to numeric values
    const monthMap = {
      "Jul": `${currentYear}-07`,
      "Aug": `${currentYear}-08`,
      "Sep": `${currentYear}-09`,
      "Oct": `${currentYear}-10`,
      "Nov": `${currentYear}-11`,
      "Dec": `${currentYear}-12`,
      "Jan": `${currentYear}-01`,
      "Feb": `${currentYear}-02`,
      "Mar": `${currentYear}-03`,
      "Apr": `${currentYear}-04`,
      "May": `${currentYear}-05`,
      "Jun": `${currentYear}-06`,
    };

    // console.log('monthMap=', monthMap);

    // Extract labels, revenue, and occupancy dynamically
    const labels = Object.keys(monthMap);
    // console.log('labels=', labels);

    const revenues = labels.map(label => {
      const monthData = occupancyRevenueByMonth.find(item => item.date === monthMap[label]);
      return monthData ? monthData.revenue : 0; // Default to 0 if no data found
    });

    const occupancies = labels.map(label => {
      const monthData = occupancyRevenueByMonth.find(item => item.date === monthMap[label]);
      return monthData ? monthData.occupancy * 100 : 0; // Convert to percentage
    });

    const chartData = {
      labels,
      datasets: [
        {
          type: 'bar',
          label: 'Average Revenue',
          data: revenues,
          backgroundColor: '#002244',
          borderRadius: 9,
          barThickness: barThickness, // Dynamic barThickness
          yAxisID: 'y',
        },
        {
          type: 'line',
          label: 'Occupancy Rate',
          data: occupancies,
          borderColor: '#FFD700',
          borderWidth: 2,
          tension: 0.4,
          yAxisID: 'y1',
        },
      ],
    };

    // console.log('chartData=', chartData);

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true, // Use a custom shape for legend items
            pointStyle: 'rect', // Use squares instead of circles
            font: {
              size: 18, // Increase font size for legend labels
            },
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              if (context.dataset.label === 'Average Revenue') {
                return `£${context.raw}`;
              } else if (context.dataset.label === 'Occupancy Rate') {
                return `${context.raw}%`;
              }
            },
          },
        },
      },
      scales: {
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: '',
            font: {
              size: 18, // Increase font size for y-axis title
            },
          },
          ticks: {
            callback: (value) => `£${value}`,
            font: {
              size: 16, // Increase font size for y-axis tick labels
            },
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: '',
            font: {
              size: 18, // Increase font size for right y-axis title
            },
          },
          ticks: {
            callback: (value) => `${value}%`,
            font: {
              size: 16, // Increase font size for right y-axis tick labels
            },
          },
          grid: {
            drawOnChartArea: false, // Disable grid lines on the right axis
          },
        },
      },
    };

    PerformanceChart = () => {
      return <Chart type="bar" data={chartData} options={options} />;
    };
  }
  // todo test --------------------------------------------- end

  const handleScroll = () => {
    const element = document.getElementById('gatsby-focus-wrapper');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // if not_london => default = shiraz
  // if london => default = akim
  let widgetUrl = cityName == "not_london"
    ? "https://meetings.hubspot.com/shiraz-osman?embed=true"
    : "https://meetings.hubspot.com/akim-courail?embed=true";

  const [isAgentSelected, setIsAgentSelected] = useState(false); // default is false
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [user_address, setUserAddress] = useState(null);
  const [user_numOfBedrooms, setUserNumOfBedrooms] = useState(null);
  const [user_phone, setUserPhone] = useState(null);
  const [user_email, setUserEmail] = useState(null);
  const [user_firstName, setUserFirstName] = useState(null);
  const [user_lastName, setUserLastName] = useState(null);

  const onSelectAgent = (agent) => {
    setSelectedAgent(agent);
    setIsAgentSelected(true);
  };

  useEffect(() => {
  }, [selectedAgent]); // This runs whenever selectedAgent changes

  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);

  useEffect(() => {
    if (cityName == 'london') {
      if (state && state.property_address)
        setUserAddress(state.property_address)

      if (state && state.number_of_bedrooms)
        setUserNumOfBedrooms(state.number_of_bedrooms)

      if (state && state.field1)
        setUserFirstName(state.field1)

      if (state && state.field2)
        setUserLastName(state.field2)

      if (state && state.field3)
        setUserEmail(state.field3)

      if (state && state.field4)
        setUserPhone(state.field4)
    }

    if (typeof state?.estimate === 'undefined') {
      return navigate('/pricing/');
    }
    else {
      setXValue(Math.round(state.estimate * 1.1 / 10) * 10);
      setYValue(Math.round(state.estimate * 1.3 / 10) * 10);
    }
  }, []);

  const resultsLabels = {
    "en": {
      "heading": section?.heading,
      "income": "Potential income per month",
      "disclaimer": section?.disclaimer,
      "assumptionsHeading": section?.assumptions?.heading,
      "assumptions": section?.assumptions?.assumptions?.map((item) => (item.assumption)),
      "calendlyHeading": section?.calendlyHeading,
      "currency": "£",
    },
    ...pricingResultsLabels,
  }

  const new_labels = resultsLabels[state?.language || 'en'];

  // Do NOT move this code up
  if (selectedAgent) {
    if (selectedAgent == 'akim') {
      widgetUrl = "https://meetings.hubspot.com/akim-courail?embed=true";
    }
    else if (selectedAgent == 'dominique') {
      // widgetUrl = "https://meetings.hubspot.com/dominique-laretive?uuid=ad1b31f3-76ac-4828-af3d-3803a6dc911c";
      widgetUrl = "https://meetings.hubspot.com/dominique-laretive?embed=true";
    }
    else {
      widgetUrl = "https://meetings.hubspot.com/shiraz-osman?embed=true";
    }
  }

  return state?.estimate ? (
    <Layout>
      <Seo post={wpPage} />
      <CustomSEO seo={wpPage?.seo} />
      <section className="my-20 md:my-36">

        {cityName === "london" ? (
          <div className="container">
            <h1 className="text-h1 text-center mb-12">Your Property’s Estimated Revenue</h1>
            <h4 className='h4-after-h1'>This estimate reflects what you could earn from flexible letting in London.</h4>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 max-w-5xl mx-auto w-full">
              <div className="col-span-1 md:col-span-6 flex flex-col justify-center py-16 px-6 bg-navy-dark text-white" id='img-form-cont'>

                <div id="center-icon">
                  <StaticImage src={'../../static/svg/key-hand.svg'} className="w-full lg:w-[42rem]" alt="CityRelay" />
                </div>

                <div id='form-data-container'>
                  <div className='one-row'>
                    <label>Addres:</label>
                    <span>{user_address}</span>
                  </div>

                  <div className='one-row'>
                    <label>Property:</label>
                    <span>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_5671_1176)">
                          <path d="M9.5 9.5C9.5 9.99445 9.35338 10.4778 9.07867 10.8889C8.80397 11.3 8.41352 11.6205 7.95671 11.8097C7.49989 11.9989 6.99723 12.0484 6.51227 11.952C6.02732 11.8555 5.58186 11.6174 5.23223 11.2678C4.8826 10.9181 4.6445 10.4727 4.54804 9.98773C4.45157 9.50277 4.50108 9.00011 4.6903 8.54329C4.87952 8.08648 5.19995 7.69603 5.61107 7.42133C6.0222 7.14662 6.50555 7 7 7C7.66304 7 8.29893 7.26339 8.76777 7.73223C9.23661 8.20107 9.5 8.83696 9.5 9.5ZM24.5 12V10C24.5 8.93913 24.0786 7.92172 23.3284 7.17157C22.5783 6.42143 21.5609 6 20.5 6H15.5C14.4391 6 13.4217 6.42143 12.6716 7.17157C11.9214 7.92172 11.5 8.93913 11.5 10V12H24.5ZM2.5 14V3C2.5 2.73478 2.39464 2.48043 2.20711 2.29289C2.01957 2.10536 1.76522 2 1.5 2C1.23478 2 0.98043 2.10536 0.792893 2.29289C0.605357 2.48043 0.5 2.73478 0.5 3L0.5 21C0.5 21.2652 0.605357 21.5196 0.792893 21.7071C0.98043 21.8946 1.23478 22 1.5 22C1.76522 22 2.01957 21.8946 2.20711 21.7071C2.39464 21.5196 2.5 21.2652 2.5 21V19H22.5V21C22.5 21.2652 22.6054 21.5196 22.7929 21.7071C22.9804 21.8946 23.2348 22 23.5 22C23.7652 22 24.0196 21.8946 24.2071 21.7071C24.3946 21.5196 24.5 21.2652 24.5 21V14H2.5Z" fill="#FAF7F4" />
                        </g>
                        <defs>
                          <clipPath id="clip0_5671_1176">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                      &nbsp;{user_numOfBedrooms} {user_numOfBedrooms === 1 ? "bedroom" : "bedrooms"}
                    </span>
                  </div>

                  <div className='one-row'>
                    <label>Owner:</label>
                    <span>{user_firstName} {user_lastName}</span>
                  </div>

                  <div className='one-row'>
                    <label>Phone no:</label>
                    <span>{user_phone}</span>
                  </div>

                  <div className='one-row'>
                    <label>Email:</label>
                    <span>{user_email}</span>
                  </div>
                </div>
              </div>

              <div className="col-span-1 md:col-span-6 bg-white p-10 border border-opacity-5 border-black flex flex-col">
                {section.calendlyHeading && <h3 className="text-h4 mb-8">{new_labels.calendlyHeading}</h3>}

                {/* if agent not selected yet AND city is london => show 2 agents to select one */}
                {
                  !isAgentSelected && cityName == "london" &&
                  <div className="custom1">
                    <label className="text-h5">Select your agent</label>

                    <div id="agents">
                      <div id="agent1" onClick={() => onSelectAgent('akim')}>
                        <img className="responsive-img" />
                        <span className='text-h3'>Akim Courail-Jones</span>
                      </div>

                      <div id="agent2" onClick={() => onSelectAgent('dominique')}>
                        <img className="responsive-img" />
                        <span className='text-h3'>Dominique Laretive</span>
                      </div>
                    </div>
                  </div>
                }

                {/* if the agent is already selected OR city is not_london */}

                {(isAgentSelected || cityName == "not_london") && <div className="bg-seafoam flex-auto p-5 flex items-center justify-center custom2">
                  <InlineWidget
                    styles={{
                      width: '100%',
                    }}
                    pageSettings={{
                      hideEventTypeDetails: true,
                      hideLandingPageDetails: true,
                    }}
                    url={widgetUrl}
                    prefill={{
                      email: state?.email || '',
                      name: state?.full_name || '',
                    }}
                  />
                </div>}

              </div>
            </div>

            <div id="yellow-cards-container">

              <div className={`w-48 lg:w-44 p-4 flex flex-col justify-center mb-2 bg-yellow`}>
                <div className='top-left-icon'>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="14.5" stroke="#12203A" stroke-width="3" />
                    <path d="M8 14.6666L13.2929 19.9595C13.6834 20.35 14.3166 20.35 14.7071 19.9595L24 10.6666" stroke="#12203A" stroke-width="3" stroke-linecap="round" />
                  </svg>
                </div>
                <div className='beside-icon'>
                  <p className='text-h5 text-center'>Annual revenue</p>
                  <span>£{annual_revenue}</span>
                </div>
              </div>

              <div className={`w-48 lg:w-44 p-4 flex flex-col justify-center mb-2 bg-yellow`}>
                <div className='top-left-icon'>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="14.5" stroke="#12203A" stroke-width="3" />
                    <path d="M8 14.6666L13.2929 19.9595C13.6834 20.35 14.3166 20.35 14.7071 19.9595L24 10.6666" stroke="#12203A" stroke-width="3" stroke-linecap="round" />
                  </svg>
                </div>
                <div className='beside-icon'>
                  <p className='text-h5 text-center'>Avg. daily rate</p>
                  <span>£{avg_daily_rate}</span>
                </div>
              </div>

              <div className={`w-48 lg:w-44 p-4 flex flex-col justify-center mb-2 bg-yellow`}>
                <div className='top-left-icon'>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.3782 4.33901C20.6866 -1.40586 11.4154 -1.44905 5.67055 4.24264C-0.0743208 9.93432 -0.117508 19.2054 5.57418 24.9503C5.61855 24.995 5.66318 25.0395 5.70812 25.0837L10.9569 29.9304C13.7622 32.6766 18.2445 32.6884 21.0643 29.9571L26.3782 25.0464C32.0962 19.3282 32.0962 10.0573 26.3782 4.33901ZM24.0114 18.0206C24.0114 19.8586 22.5215 21.3485 20.6836 21.3485H11.3655C9.52755 21.3485 8.03762 19.8586 8.03762 18.0206V13.532C8.03762 12.2229 8.67918 10.9969 9.7548 10.2507L13.7482 7.48057C15.1181 6.53395 16.9309 6.53395 18.3008 7.48057L22.2942 10.2507C23.3699 10.9969 24.0114 12.2229 24.0114 13.532V18.0206ZM21.3491 13.5319V18.0206C21.3491 18.3881 21.0511 18.6861 20.6836 18.6861H18.6868V16.0238C18.6868 15.2886 18.0908 14.6927 17.3557 14.6927H14.6934C13.9582 14.6927 13.3622 15.2887 13.3622 16.0238V18.6861H11.3655C10.9979 18.6861 10.6999 18.3881 10.6999 18.0206V13.5319C10.6998 13.0954 10.9137 12.6866 11.2723 12.4378L15.2657 9.66764C15.7227 9.35339 16.3262 9.35339 16.7832 9.66764L20.7767 12.4378C21.1354 12.6866 21.3492 13.0954 21.3491 13.5319Z" fill="#12203A" />
                  </svg>
                </div>
                <div className='beside-icon'>
                  <p className='text-h5 text-center'>Avg. occupancy</p>
                  <span>{occupancy}%</span>
                </div>
              </div>
            </div>

            <div className='max-w-5xl mx-auto w-full' id="chart-container">

              <div id="chart">
                <span>Performance indicators</span>
                <PerformanceChart />
              </div>

              <div id="chart-p">
                <p>Let us show you how City Relay can turn this projection into reality - where you get maximum rental income and occupancy without the hassle of managing it all yourself!
                </p>
              </div>
            </div>

            {/* 2 sand boxes here */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 max-w-5xl mx-auto w-full">

              {/* first sand box */}
              <div id='get-started' className="col-span-1 md:col-span-6 flex flex-col justify-center py-16 px-6 bg-sand text-navy">
                <h4>Get Started in 3 Simple Steps</h4>

                {/* desktop only */}
                <div className='one-row hidden lg:block'>
                  <svg width="72" height="71" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="35.5" r="35.5" fill="#F9DC72" />
                    <path d="M27.7129 32.6367V27.2227H28.5039C30.2148 27.1875 31.6152 26.8242 32.7051 26.1328C33.7949 25.4414 34.6152 24.3926 35.166 22.9863H40.3164V48H34.1289V30.9668C33.6133 31.4824 32.8926 31.8926 31.9668 32.1973C31.0527 32.4902 30.0566 32.6367 28.9785 32.6367H27.7129Z" fill="black" />
                  </svg>

                  <svg width="72" height="71" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="35.5" r="35.5" fill="#F9DC72" />
                    <path d="M24.0918 48V43.5703C29.3066 41.1328 33.209 38.9707 35.7988 37.084C37.1816 36.0762 38.1543 35.0977 38.7168 34.1484C39.291 33.1992 39.5781 32.1387 39.5781 30.9668C39.5781 29.748 39.209 28.7871 38.4707 28.084C37.7324 27.3691 36.6367 27.0117 35.1836 27.0117C33.7422 27.0117 32.6113 27.3867 31.791 28.1367C30.9824 28.875 30.5781 29.9062 30.5781 31.2305C30.5781 31.6523 30.6367 32.2031 30.7539 32.8828L24.3906 32.2676C24.2969 31.7871 24.25 31.2246 24.25 30.5801C24.25 28.084 25.2637 26.0801 27.291 24.5684C29.3301 23.0566 32.0254 22.3008 35.377 22.3008C38.7988 22.3008 41.459 23.0273 43.3574 24.4805C45.2559 25.9336 46.2051 27.9434 46.2051 30.5098C46.2051 32.2207 45.7832 33.7617 44.9395 35.1328C44.1074 36.4922 42.8711 37.7695 41.2305 38.9648C39.0039 40.5234 36.2676 41.9297 33.0215 43.1836H46.5918V48H24.0918Z" fill="black" />
                  </svg>

                  <svg width="72" height="71" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="35.5" r="35.5" fill="#F9DC72" />
                    <path d="M23.8105 40.2656L30.1211 39.6855C30.2148 41.0918 30.7129 42.1758 31.6152 42.9375C32.5293 43.6992 33.7832 44.0801 35.377 44.0801C36.9473 44.0801 38.1484 43.7812 38.9805 43.1836C39.8125 42.5742 40.2285 41.7188 40.2285 40.6172C40.2285 39.4688 39.8066 38.6367 38.9629 38.1211C38.1191 37.6055 36.8477 37.3477 35.1484 37.3477H33.1094V33.3398H35.1133C36.6133 33.3398 37.7559 33.0645 38.541 32.5137C39.3379 31.9629 39.7363 31.1602 39.7363 30.1055C39.7363 29.0625 39.3379 28.2598 38.541 27.6973C37.7559 27.123 36.625 26.8359 35.1484 26.8359C33.5898 26.8359 32.3887 27.2344 31.5449 28.0312C30.7012 28.8281 30.2617 29.9531 30.2266 31.4062L24.0391 30.6504C24.0742 28.1426 25.0938 26.127 27.0977 24.6035C29.1016 23.0684 31.8496 22.3008 35.3418 22.3008C38.7988 22.3008 41.459 22.9336 43.3223 24.1992C45.1973 25.4531 46.1348 27.2051 46.1348 29.4551C46.1348 30.9668 45.6133 32.2148 44.5703 33.1992C43.5391 34.1836 42.2031 34.8105 40.5625 35.0801C42.5547 35.3379 44.0898 35.9707 45.168 36.9785C46.2461 37.9746 46.7852 39.334 46.7852 41.0566C46.7852 43.3652 45.7715 45.2168 43.7441 46.6113C41.7168 47.9941 38.916 48.6855 35.3418 48.6855C31.8379 48.6855 29.0605 47.9355 27.0098 46.4355C24.9707 44.9238 23.9043 42.8672 23.8105 40.2656Z" fill="black" />
                  </svg>
                </div>

                <div className='one-row hidden lg:block'>
                  <div>
                    <b>List your property across main booking platforms</b>
                    <p>From professional photography to listing optimisation, we’ll make your property listing stand out.
                    </p>
                  </div>

                  <div>
                    <b>24/7 guest communication</b>
                    <p>Our capable support team is ready to handle guest inquiries, requests and check-ins, 24/7.
                    </p>
                  </div>

                  <div>
                    <b>Real-time performance dashboard</b>
                    <p>Track bookings, income reports and even cleaning and maintenance schedules on our platform.
                    </p>
                  </div>
                </div>

                {/* mobile only */}
                <div className='block lg:hidden'>
                  <svg width="72" height="71" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="35.5" r="35.5" fill="#F9DC72" />
                    <path d="M27.7129 32.6367V27.2227H28.5039C30.2148 27.1875 31.6152 26.8242 32.7051 26.1328C33.7949 25.4414 34.6152 24.3926 35.166 22.9863H40.3164V48H34.1289V30.9668C33.6133 31.4824 32.8926 31.8926 31.9668 32.1973C31.0527 32.4902 30.0566 32.6367 28.9785 32.6367H27.7129Z" fill="black" />
                  </svg>
                  <div>
                    <b>List your property across main booking platforms</b>
                    <p>From professional photography to listing optimisation, we’ll make your property listing stand out.
                    </p>
                  </div>
                </div>

                <div className='block lg:hidden'>
                  <svg width="72" height="71" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="35.5" r="35.5" fill="#F9DC72" />
                    <path d="M24.0918 48V43.5703C29.3066 41.1328 33.209 38.9707 35.7988 37.084C37.1816 36.0762 38.1543 35.0977 38.7168 34.1484C39.291 33.1992 39.5781 32.1387 39.5781 30.9668C39.5781 29.748 39.209 28.7871 38.4707 28.084C37.7324 27.3691 36.6367 27.0117 35.1836 27.0117C33.7422 27.0117 32.6113 27.3867 31.791 28.1367C30.9824 28.875 30.5781 29.9062 30.5781 31.2305C30.5781 31.6523 30.6367 32.2031 30.7539 32.8828L24.3906 32.2676C24.2969 31.7871 24.25 31.2246 24.25 30.5801C24.25 28.084 25.2637 26.0801 27.291 24.5684C29.3301 23.0566 32.0254 22.3008 35.377 22.3008C38.7988 22.3008 41.459 23.0273 43.3574 24.4805C45.2559 25.9336 46.2051 27.9434 46.2051 30.5098C46.2051 32.2207 45.7832 33.7617 44.9395 35.1328C44.1074 36.4922 42.8711 37.7695 41.2305 38.9648C39.0039 40.5234 36.2676 41.9297 33.0215 43.1836H46.5918V48H24.0918Z" fill="black" />
                  </svg>
                  <div>
                    <b>24/7 guest communication</b>
                    <p>Our capable support team is ready to handle guest inquiries, requests and check-ins, 24/7.
                    </p>
                  </div>
                </div>
                <div className='block lg:hidden'>
                  <svg width="72" height="71" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="35.5" r="35.5" fill="#F9DC72" />
                    <path d="M23.8105 40.2656L30.1211 39.6855C30.2148 41.0918 30.7129 42.1758 31.6152 42.9375C32.5293 43.6992 33.7832 44.0801 35.377 44.0801C36.9473 44.0801 38.1484 43.7812 38.9805 43.1836C39.8125 42.5742 40.2285 41.7188 40.2285 40.6172C40.2285 39.4688 39.8066 38.6367 38.9629 38.1211C38.1191 37.6055 36.8477 37.3477 35.1484 37.3477H33.1094V33.3398H35.1133C36.6133 33.3398 37.7559 33.0645 38.541 32.5137C39.3379 31.9629 39.7363 31.1602 39.7363 30.1055C39.7363 29.0625 39.3379 28.2598 38.541 27.6973C37.7559 27.123 36.625 26.8359 35.1484 26.8359C33.5898 26.8359 32.3887 27.2344 31.5449 28.0312C30.7012 28.8281 30.2617 29.9531 30.2266 31.4062L24.0391 30.6504C24.0742 28.1426 25.0938 26.127 27.0977 24.6035C29.1016 23.0684 31.8496 22.3008 35.3418 22.3008C38.7988 22.3008 41.459 22.9336 43.3223 24.1992C45.1973 25.4531 46.1348 27.2051 46.1348 29.4551C46.1348 30.9668 45.6133 32.2148 44.5703 33.1992C43.5391 34.1836 42.2031 34.8105 40.5625 35.0801C42.5547 35.3379 44.0898 35.9707 45.168 36.9785C46.2461 37.9746 46.7852 39.334 46.7852 41.0566C46.7852 43.3652 45.7715 45.2168 43.7441 46.6113C41.7168 47.9941 38.916 48.6855 35.3418 48.6855C31.8379 48.6855 29.0605 47.9355 27.0098 46.4355C24.9707 44.9238 23.9043 42.8672 23.8105 40.2656Z" fill="black" />
                  </svg>
                  <div>
                    <b>List your property across main booking platforms</b>
                    <p>From professional photography to listing optimisation, we’ll make your property listing stand out.
                    </p>
                  </div>
                </div>


              </div>

              {/* second sand box */}
              <div id='choose-pricing' className="col-span-1 md:col-span-6 flex flex-col justify-center py-16 px-6 bg-sand text-navy">
                <h4>Choose a Pricing Plan That Works for You!</h4>
                <h5>Whether you’re hosting seasonally or all year round, we’ve got flexible plans that’ll suit your needs.</h5>
                {/* desktop only */}
                <div className='one-row hidden lg:block'>
                  <div id='btn-similar-1'>
                    <span >From 20%</span>
                    <span>(of the Net booking)</span>
                  </div>

                  <div id='btn-similar-2'>
                    <span>From 18%</span>
                    <span>(of the Net booking)</span>
                  </div>
                </div>

                <div className='one-row hidden lg:block'>
                  <span>For short-term hosts who want the flexibility to use their property whenever they like.
                  </span>
                  <span>For full-time hosts who can keep their properties available all year.
                  </span>
                </div>

                {/* mobile only */}
                <div className='block lg:hidden'>
                  <div id='btn-similar-1'>
                    <span >From 20%</span>
                    <span>(of the Net booking)</span>
                  </div>
                  <span className='span'>For short-term hosts who want the flexibility to use their property whenever they like.
                  </span>
                </div>

                <div className='block lg:hidden'>
                  <div id='btn-similar-2'>
                    <span>From 18%</span>
                    <span>(of the Net booking)</span>
                  </div>
                  <span className='span'>For full-time hosts who can keep their properties available all year.
                  </span>
                </div>

              </div>
            </div>

            {/* comparison + FAQ */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 max-w-5xl mx-auto w-full" id="comp-faq">

              {/* comparison box */}
              <div className="col-span-1 md:col-span-8 flex flex-col">
                <div id='comparison-container' className='bg-navy text-white'>
                  <label id="comparison-title">Why Partner with City Relay</label>

                  <table className="comparison-table">
                    <thead>
                      <tr>
                        <th id='empty-th'></th>
                        <th className="highlighted-column" id='managed-by-start'>Managed by Cityrelay</th>
                        <th>Hosting Yourself</th>
                        <th>Estate Agent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        'Short-term lets',
                        'Guest Vetting',
                        'Hassle-Free',
                        '24/7 Guest Comms',
                        'Maintenance',
                        'Pricing Algorithm',
                        'Multiple Sites',
                        'Tech Stack',
                        'Multidisciplinary Team',
                      ].map((feature, idx) => (
                        <tr key={idx} className={idx % 2 == 0 ? "semi-trans-bg" : ""} id={`cell-${idx}`}>
                          <td className='col-a'>{feature}</td>
                          <td className="highlighted-column col-b">
                            <span className="checkmark">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="10.5" fill="#12203A" stroke="#12203A" strokeWidth="3" />
                                <path
                                  d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8"
                                  stroke="white"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </span>
                          </td>
                          <td className='col-c'>
                            {idx === 0 || idx === 1 || idx === 4 || idx === 6 || idx === 8 ? (
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="10.5" fill="white" stroke="white" stroke-width="3" />
                                <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="#12203A" stroke-width="3" stroke-linecap="round" />
                              </svg>
                            ) : (
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.0001 5.99997C17.8125 5.8125 17.5582 5.70718 17.2931 5.70718C17.0279 5.70718 16.7736 5.8125 16.5861 5.99997L12.0001 10.586L7.41406 5.99997C7.22653 5.8125 6.97223 5.70718 6.70706 5.70718C6.4419 5.70718 6.18759 5.8125 6.00006 5.99997C5.81259 6.1875 5.70728 6.44181 5.70728 6.70697C5.70728 6.97213 5.81259 7.22644 6.00006 7.41397L10.5861 12L6.00006 16.586C5.81259 16.7735 5.70728 17.0278 5.70728 17.293C5.70728 17.5581 5.81259 17.8124 6.00006 18C6.18759 18.1874 6.4419 18.2928 6.70706 18.2928C6.97223 18.2928 7.22653 18.1874 7.41406 18L12.0001 13.414L16.5861 18C16.7736 18.1874 17.0279 18.2928 17.2931 18.2928C17.5582 18.2928 17.8125 18.1874 18.0001 18C18.1875 17.8124 18.2928 17.5581 18.2928 17.293C18.2928 17.0278 18.1875 16.7735 18.0001 16.586L13.4141 12L18.0001 7.41397C18.1875 7.22644 18.2928 6.97213 18.2928 6.70697C18.2928 6.44181 18.1875 6.1875 18.0001 5.99997Z" fill="white" />
                              </svg>
                            )}
                          </td>
                          <td className='col-d'>
                            {idx === 2 || idx === 4 || idx === 8 ? (
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="10.5" fill="white" stroke="white" stroke-width="3" />
                                <path d="M6 11L9.79289 14.7929C10.1834 15.1834 10.8166 15.1834 11.2071 14.7929L18 8" stroke="#12203A" stroke-width="3" stroke-linecap="round" />
                              </svg>
                            ) : (
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.0001 5.99997C17.8125 5.8125 17.5582 5.70718 17.2931 5.70718C17.0279 5.70718 16.7736 5.8125 16.5861 5.99997L12.0001 10.586L7.41406 5.99997C7.22653 5.8125 6.97223 5.70718 6.70706 5.70718C6.4419 5.70718 6.18759 5.8125 6.00006 5.99997C5.81259 6.1875 5.70728 6.44181 5.70728 6.70697C5.70728 6.97213 5.81259 7.22644 6.00006 7.41397L10.5861 12L6.00006 16.586C5.81259 16.7735 5.70728 17.0278 5.70728 17.293C5.70728 17.5581 5.81259 17.8124 6.00006 18C6.18759 18.1874 6.4419 18.2928 6.70706 18.2928C6.97223 18.2928 7.22653 18.1874 7.41406 18L12.0001 13.414L16.5861 18C16.7736 18.1874 17.0279 18.2928 17.2931 18.2928C17.5582 18.2928 17.8125 18.1874 18.0001 18C18.1875 17.8124 18.2928 17.5581 18.2928 17.293C18.2928 17.0278 18.1875 16.7735 18.0001 16.586L13.4141 12L18.0001 7.41397C18.1875 7.22644 18.2928 6.97213 18.2928 6.70697C18.2928 6.44181 18.1875 6.1875 18.0001 5.99997Z" fill="white" />
                              </svg>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

                {/* text below the table */}
                <div id='after-table'>
                  <label>City Relay is ready to manage your property and optimise its potential</label>
                  <button onClick={handleScroll} className='btn btn--primary false !py-4'>Book a Call Now!</button>
                </div>

              </div>

              {/* FAQ box */}
              <div id='faq' className="col-span-1 md:col-span-4 flex flex-col px-6 bg-sand text-navy">
                <label id="faq-title">Frequently Asked Questions</label>

                <div className="faq-details">
                  <span className='que'>Why does City Relay charge a commission on the net rent instead of the gross rent?</span>
                  <span className='ans'>At City Relay, we believe in fair and transparent pricing. Unlike competitors who charge a commission on the gross rent (the total booking amount), we charge our commission on the net rent.
                    <br />
                    Net rent is the amount left after deducting channel fees (like Airbnb or Booking.com fees) and payment processing fees. By charging a commission only on the net rent, we ensure you’re paying based on what remains in your pocket after all fees. This approach is more aligned with your actual earnings and avoids the inflated charges that come with gross rent-based commissions.
                  </span>
                </div>

                <div className="faq-details">
                  <span className='que'>What will the call be about?
                  </span>
                  <span className='ans'>We’ll discuss your property’s unique features to refine the revenue estimate and get a more accurate projection. We’ll also answer your questions about getting started so we can list your property immediately.</span>
                </div>

                <div className="faq-details">
                  <span className='que'>How will City Relay help me earn more?</span>
                  <span className='ans'>We use flexible letting and a smart pricing algorithm that automatically adjusts rates to ensure maximum earning potential. This dynamic pricing strategy will keep your property competitive in London’s private rental market.
                  </span>
                </div>

                <div className="faq-details">
                  <span className='que'>Does City Relay handle everything?</span>
                  <span className='ans'>Yes! We’ll take care of the whole property management process, from professional cleaning and hotel-quality linens to 24/7 guest support and maintenance. We’ll even list your property if you haven’t done it yet.
                  </span>
                </div>

              </div>

            </div>
          </div>
        ) : (
          <div className="container">
            <h1 className="text-h1 text-center mb-12">{new_labels.heading}</h1>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 max-w-5xl mx-auto w-full">
              <div className="col-span-1 md:col-span-5 flex flex-col justify-center py-16 px-6 bg-navy-dark text-white">
                {state?.estimate && (
                  <div className="space-y-3 mb-12 md:mb-16 text-center max-w-xs mx-auto">
                    <h2 className="text-h6">{new_labels.income}</h2>
                    <h3 className="text-h2">{new_labels.currency}{xValue} - {new_labels.currency}{yValue}</h3>
                    <p className="opacity-40 text-xs">{new_labels.disclaimer}</p>
                  </div>
                )}

                {section.assumptions && (
                  <div className="max-w-xs mx-auto">
                    <h4 className="text-h5 mb-6 text-center">{new_labels.assumptionsHeading}</h4>
                    <div className="flex flex-col justify-center">
                      <div className="text-sm space-y-3 max-w-[16rem]  mx-auto">
                        {section.assumptions.assumptions?.map((item, i) => (
                          <div key={`assumption${i}`} className="grid grid-cols-12 gap-4 items-center">
                            <div className="col-span-3">
                              <div className="aspect-w-1 aspect-h-1">
                                <div>{item.illustration && <Image data={item.illustration} />}</div>
                              </div>
                            </div>
                            <div className="col-span-9">{new_labels.assumptions[i]}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

              </div>

              <div className="col-span-1 md:col-span-7 bg-white p-10 border border-opacity-5 border-black flex flex-col">
                {section.calendlyHeading && <h3 className="text-h4 mb-8">{new_labels.calendlyHeading}</h3>}

                {/* if the agent is already selected OR city is not_london */}

                {(isAgentSelected || cityName == "not_london") && <div className="bg-seafoam flex-auto p-5 flex items-center justify-center custom2">
                  <InlineWidget
                    styles={{
                      width: '100%',
                    }}
                    pageSettings={{
                      hideEventTypeDetails: true,
                      hideLandingPageDetails: true,
                    }}
                    url={widgetUrl}
                    prefill={{
                      email: state?.email || '',
                      name: state?.full_name || '',
                    }}
                  />
                </div>}

              </div>
            </div>

          </div>
        )}
      </section>
    </Layout>
  ) : null
}

export const pageQuery = graphql`
  query pricingResultQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      pricingResult {
        pricingResult {
          assumptions {
            heading
            assumptions {
              illustration {
                ... Image
              }
              assumption
            }
          }
          disclaimer
          heading
          calendlyHeading
        }
      }
    }
  }
`
